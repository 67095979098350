<template>
  <div>
    <Header />
    <div class="bg pic-full"></div>
    <!-- <warn class="warn" :home-lang="home"></warn> -->
    <div class="home">
      <main class="donation-main">
        <donation-introduction
          class="donation-introduction"
        ></donation-introduction>
        <donation-content class="donation-content"></donation-content>
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Layouts/Header.vue';
import Footer from '../components/Layouts/Footer.vue';
// import Warn from "@/components/contents/warn";
import DonationIntroduction from '@/components/donation/donationIntroduction';
import DonationContent from '@/components/donation/donationContent';

export default {
  name: 'Donation',
  components: {
    // Warn,
    DonationContent,
    DonationIntroduction,
    Header,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less">
@media screen and (min-width: 767px) {
  .donation-main {
    width: 10rem;
    margin: 0 auto 1.37rem;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  .donation-main {
    margin-bottom: 0.3rem;
  }
}
</style>
