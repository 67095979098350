<template>
  <div>
    <Header />
    <div class="bg pic-full"></div>
    <!-- <warn class="warn" :home-lang="home"></warn> -->
    <div class="home">
      <main class="stake-main">
        <stake-introduction class="stake-introduction"></stake-introduction>
        <stake-content class="stake-content"></stake-content>
        <stake-vote class="stake-vote"></stake-vote>
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Layouts/Header.vue';
import Footer from '../components/Layouts/Footer.vue';
import StakeIntroduction from '@/components/stake/stakeIntroduction';
import StakeContent from '@/components/stake/stakeContent';
import StakeVote from '@/components/stake/stakeVote';

export default {
  name: 'Stake',
  components: {
    Header,
    Footer,
    StakeContent,
    StakeIntroduction,
    StakeVote,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less">
@media screen and (min-width: 767px) {
  .stake-main {
    width: 10rem;
    margin: 0 auto 1.37rem;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  .stake-main {
    margin-bottom: 0.3rem;
  }
}
</style>
