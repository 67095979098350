<template>
  <div class="stake-content-container pic-full">
    <modal
      classes="deposit-modal"
      color="#49d663"
      :adaptive="true"
      width="88%"
      height="auto"
      :maxWidth="420"
      name="deposit-panel"
    >
      <h3 class="title">{{ $t('stake.deposit') }}</h3>

      <div class="wrap">
        <p>
          TPT {{ $t('stake.balance') }}
          <span class="float-right">{{ tptBalance }}</span>
        </p>

        <p style="position: relative">
          <input type="text" v-model="stakeAmount" />

          <span @click="stakeAmount = tptBalance" class="btn primary all-btn">{{
            $t('stake.all')
          }}</span>
        </p>

        <button @click="stake()" class="btn primary">
          {{ $t('stake.confirm') }}
        </button>
      </div>
    </modal>

    <modal
      classes="withdraw-modal"
      color="#49d663"
      :adaptive="true"
      width="88%"
      height="auto"
      :maxWidth="420"
      name="withdraw-panel"
    >
      <h3 class="title">{{ $t('stake.withdraw') }}</h3>

      <div class="wrap">
        <p>
          xTPT {{ $t('stake.balance') }}
          <span class="float-right">{{ xtptBalance }}</span>
        </p>
        <p style="position: relative">
          <input type="text" v-model="unstakeAmount" />

          <span
            @click="unstakeAmount = xtptBalance"
            class="btn primary all-btn"
            >{{ $t('stake.all') }}</span
          >
        </p>

        <button @click="unstake()" class="btn primary">
          {{  $t('stake.confirm') }}
        </button>
      </div>
    </modal>

    <modal
      classes="msg-modal"
      color="#49d663"
      :adaptive="true"
      width="88%"
      height="auto"
      :maxWidth="420"
      name="msg-panel"
    >
      <div class="wrap">
        <p>
          {{ msg }}
        </p>
      </div>
    </modal>

    <p class="title">{{ $t('stake.join') }}</p>
    <div class="account-info">
      <span class="dot"></span>
      <span v-if="logined">{{ currentAddress }}</span>
      <span v-else>{{ $t('stake.loginFirst') }}</span>
    </div>
    <div class="amount-container">
      <div class="staked-amount">
        {{ xtptBalance }}
      </div>

      <span class="symbol"> TPT</span>
    </div>

    <div v-if="!logined" class="btn-container">
      <button @click="login()" class="btn primary">
        {{ $t('stake.connectWallet') }}
      </button>
    </div>
    <div v-else-if="!networkvalid" class="btn-container">
      <button class="btn disable">{{ $t('stake.networkNotvalid') }}</button>
    </div>
    <div v-else-if="!approved" class="btn-container">
      <button class="btn primary" @click="approveTPT()">
        {{ $t('stake.approve') }}
      </button>
    </div>

    <div v-else class="btn-container col-2">
      <button @click="showWithdraw()" class="btn">
        {{ $t('stake.unstake') }}
      </button>
      <button @click="showAdd()" class="btn primary">
        {{ $t('stake.add ') }}
      </button>
    </div>

    <!-- <div class="QRCode-container pic-full flex-center">
      <div
        class="warn-container flex-center pic-full"
        v-if="!chosenChain.title"
      >
        {{ homeLang.stake.warn }}
      </div>
      <img
        :src="chosenChain.QRCode"
        class="QRCode"
        v-if="chosenChain.title"
        :class="chosenChain.title"
      />
    </div> -->
    <!-- <p class="transfer">{{ homeLang.stake.transfer }}</p>
    <p class="address">{{ stakeAddress }}</p>
    <div
      class="button-container pic-full flex-center"
      :class="mouseDown ? 'down' : 'normal'"
      @mousedown="mouseDown = true"
      @mouseup="mouseDown = false"
      @touchstart="mouseDown = true"
      @touchend="mouseDown = false"
      v-clipboard:copy="stakeAddress"
      v-clipboard:success="clipboardSuccess"
      v-if="chosenChain.title"
    >
      <span>{{ homeLang.stake.copyAddress }}</span>
    </div> -->
  </div>
</template>

<script>
import Web3 from "web3";
import clipboard from "@/directive/clipboard";

const TPT_STAKE_CONTRACT_BSC = "0x99cF5A4Ae5efF6f661c36e2A536c33075dbaf4DF";

const TPT_TOKEN_CONTRACT = "0xECa41281c24451168a37211F0bc2b8645AF45092";

const TokenAbi = [
  {
    constant: false,
    inputs: [
      { name: "_spender", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      { name: "_owner", type: "address" },
      { name: "_spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ name: "remaining", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "payAmount",
        type: "uint256",
      },
    ],
    name: "deposit",
    outputs: [
      {
        internalType: "uint256",
        name: "recevieAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "withdrawAmount",
        type: "uint256",
      },
    ],
    name: "withdraw",
    outputs: [
      {
        internalType: "uint256",
        name: "recevieAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export default {
  name: "stakeContent",
  data() {
    return {
      mouseDown: false,
      chosenChain: {
        title: undefined,
        logo: undefined,
        QRCode: undefined,
      },
      slideShow: false,
      stakeAddress: "",
      xtptBalance: "0.0000",
      tptBalance: "0",
      currentAddress: "",
      networkvalid: false,
      logined: false,
      web3: null,
      approved: false,
      stakeAmount: "",
      unstakeAmount: "",
      msg: "",
      isConfirmed: false,
    };
  },
  mounted() {
    window.addEventListener("click", () => {
      this.slideShow = false;
    });

    ethereum.on("accountsChanged", () => {
      this.login();
    });

    ethereum.on("chainChanged", () => {
      this.login();
    });

    setTimeout(() => {
      this.login();
    }, 1000);
  },
  directives: {
    clipboard,
  },
  methods: {
    login() {
      if (window.ethereum) {
        window.ethereum.enable().then((res) => {
          var account = res[0];
          var chainId = ethereum.chainId;
          this.currentAddress = account;
          this.logined = true;

          if (chainId && Number(chainId) === 56) {
            this.networkvalid = true;
            this.web3 = new Web3(window.ethereum);
            this.queryInfo();
          }
        });
      }
    },
    approveTPT() {
      var tptContract = new this.web3.eth.Contract(
        TokenAbi,
        TPT_TOKEN_CONTRACT
      );
      tptContract.methods
        .approve(
          TPT_STAKE_CONTRACT_BSC,
          "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
        )
        .send({ from: this.currentAddress })
        .on("transactionHash", (hash) => {
          this.isConfirmed = false;
          this.msg = this.$t('stake.success');
          this.$modal.show("msg-panel");
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          if (!this.isConfirmed) {
            this.queryInfo();
            this.isConfirmed = true;
          }
        })
        .on("error", (error, receipt) => {
          this.isConfirmed = true;
        });
    },
    queryInfo() {
      var tptStakeContract = new this.web3.eth.Contract(
        TokenAbi,
        TPT_STAKE_CONTRACT_BSC
      );

      tptStakeContract.methods
        .balanceOf(this.currentAddress)
        .call()
        .then((res) => {
          this.xtptBalance = parseFloat(res / Math.pow(10, 4)).toFixed(4);
        });

      var tptContract = new this.web3.eth.Contract(
        TokenAbi,
        TPT_TOKEN_CONTRACT
      );

      tptContract.methods
        .balanceOf(this.currentAddress)
        .call()
        .then((res) => {
          this.tptBalance = parseFloat(res / Math.pow(10, 4)).toFixed(4);
        });

      tptContract.methods
        .allowance(this.currentAddress, TPT_STAKE_CONTRACT_BSC)
        .call()
        .then((res) => {
          console.log(res)
          if (res > 0) {
            this.approved = true;
          } else {
            this.approved = false;
          }
        });
    },
    showAdd() {
      this.$modal.show("deposit-panel");
    },
    showWithdraw() {
      this.$modal.show("withdraw-panel");
    },
    stake() {
      if (+this.stakeAmount > +this.tptBalance) {
        this.msg = this.$t('stake.overbalance');
        this.$modal.show("msg-panel");
        return;
      }

      if (+this.stakeAmount > 0) {
        var tptStakeContract = new this.web3.eth.Contract(
          TokenAbi,
          TPT_STAKE_CONTRACT_BSC
        );

        tptStakeContract.methods
          .deposit(parseFloat(this.stakeAmount * 10000).toFixed(0))
          .send({ from: this.currentAddress })
          .on("transactionHash", (hash) => {
            this.isConfirmed = false;
            this.msg = this.$t('stake.success');
            this.$modal.show("msg-panel");
          })
          .on("confirmation", (confirmationNumber, receipt) => {
            if (!this.isConfirmed) {
              this.queryInfo();
              this.isConfirmed = true;
            }
          })
          .on("error", (error, receipt) => {
            console.log(error);
            this.isConfirmed = true;
          });
      }
    },
    unstake() {
      if (+this.unstakeAmount > +this.xtptBalance) {
        this.msg = this.$t('stake.overbalance');
        this.$modal.show("msg-panel");
        return;
      }

      if (+this.unstakeAmount > 0) {
        var tptStakeContract = new this.web3.eth.Contract(
          TokenAbi,
          TPT_STAKE_CONTRACT_BSC
        );

        tptStakeContract.methods
          .withdraw(parseFloat(this.unstakeAmount * 10000).toFixed(0))
          .send({ from: this.currentAddress })
          .on("transactionHash", (hash) => {
            this.isConfirmed = false;
            this.msg = this.$t('stake.success');
            this.$modal.show("msg-panel");
          })
          .on("confirmation", (confirmationNumber, receipt) => {
            console.log("confirmed", receipt);
            if (!this.isConfirmed) {
              this.queryInfo();
              this.isConfirmed = true;
            }
          })
          .on("error", (error, receipt) => {
            console.log(error);
            this.isConfirmed = true;
          });
      }
    },
    clipboardSuccess() {
      this.$toast({
        text: this.$t('stake.copied'),
      });
    },
  },
};
</script>

<style lang="less" >
.msg-modal,
.withdraw-modal,
.deposit-modal {
  border-radius: 0.16rem;
  font-size: 0.16rem;
  padding-bottom: 0.3rem;

  .float-right {
    float: right;
  }

  input {
    width: 100%;
    padding: 0.16rem;
    margin: 0.1rem 0;
    border-radius: 0.16rem;
    border: 1px solid #d8d8d8;
  }

  p {
    font-size: 0.13rem;
  }

  h3.title {
    color: #242424;
    margin: auto;
    text-align: center;
    padding: 0.16rem 0.16rem 0.12rem;
    font-size: 0.18rem;
  }

  .wrap {
    padding: 0.12rem 0.25rem;
  }
}

.msg-modal {
  padding: 0.3rem;
  text-align: center;
  font-size: 0.16rem !important;
}

.amount-container {
  position: relative;
  border: 2px solid #fff;
  width: 80%;
  height: 1.2rem;
  border-radius: 4px;

  .symbol {
    opacity: 0.6;
    display: inline-block;
    padding: 2px 8px;
    background-color: #fff;
    color: #666;
    font-size: 0.16rem;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.account-info {
  width: 80%;
  text-align: left;
  font-size: 0.14rem;
  margin: 0.12rem 0;
  color: #333;
}

.dot {
  display: inline-block;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #57d4aa;
  margin-right: 0.04rem;
}

.staked-amount {
  font-size: 0.24rem;
  text-align: center;
  margin: 0.4rem auto;
  color: #2761e7;
}

.btn-container {
  width: 80%;
}

.btn {
  font-size: 0.18rem;
  outline: none;
  border-radius: 1rem;
  width: 100%;
  padding: 0.27rem 0.54rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &.disable {
    color: #999;
  }

  &.primary {
    background-color: transparent;
    color: #fff;
    border-radius: 0;
    background-image: url("../../assets/pc/stake/btn-long.png");

    &:active {
      background-image: url("../../assets/pc/stake/btn-long-active.png");
      transform: translate(0.02rem, 0.02rem);
    }

    &.all-btn {
      position: absolute;
      top: 0.18rem;
      right: 0.04rem;
      width: auto;
      padding: 0.08rem 0.12rem;
      background-color: transparent;
      font-size: 0.12rem;
      color: #fff;
      background-image: url("../../assets/pc/stake/btn.png");

      &:active {
        background-image: url("../../assets/pc/stake/btn-active.png");
        transform: translate(0.02rem, 0.02rem);
      }
    }
  }
}
.btn-container.col-2 {
  .btn {
    width: 50%;
    background-color: transparent;
    background-image: url("../../assets/pc/stake/btn-normal.png");
    color: #2761e7;

    &.primary {
      background-color: transparent;
      color: #fff;
      background-image: url("../../assets/pc/stake/btn.png");

      &:active {
        background-image: url("../../assets/pc/stake/btn-active.png");
        transform: translate(0.02rem, 0.02rem);
      }
    }
  }
}

@media screen and (min-width: 767px) {
  .stake-content-container {
    position: absolute;
    left: 5.5rem;
    top: 0.63rem;
    width: 4.36rem;
    height: 5.6rem;
    background-image: url("../../assets/pc/stake/bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    &::after {
      content: "";
      width: 5.3rem;
      height: 8.13rem;
      position: absolute;
      background-image: url("../../assets/pc/stake/bgShadow.png");
      background-size: 100%;
      background-repeat: no-repeat;
      z-index: -79;
      left: 0.19rem;
      top: 0.41rem;
    }

    .title {
      font-size: 0.26rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: bold;
      color: #333333;
      line-height: 0.37rem;
      margin-top: 1.13rem;
      padding: 0.09rem 0.39rem 0.05rem;
      width: auto;
      height: auto;
      border-radius: 0.37rem;
      background-color: rgba(255, 255, 255, 0.2);
    }
    .label-p {
      font-size: 0.18rem;
      line-height: 0.25rem;
      label {
        font-family: PingFangSC, PingFangSC-Regular;
        color: #333333;
      }
    }
    .stake-chain-container {
      margin-top: 0.25rem;
      width: 3.4rem;
      cursor: pointer;

      .stake-chain {
        box-sizing: content-box;
        margin-left: -0.02rem;
        width: 3.4rem;
        height: 0.44rem;
        background: rgba(255, 255, 255, 0.2);
        border: 0.02rem solid #ffffff;
        border-radius: 0.04rem;
        margin-top: 0.08rem;
        display: flex;
        align-items: center;

        .chain-container {
          width: 0.28rem;
          height: 0.28rem;
          margin-left: -0.08rem;

          &:first-child {
            margin-left: 0.16rem;
          }
        }
        .chain-title {
          font-size: 0.14rem;
          font-family: PingFangSC, PingFangSC-Regular;
          color: #3a3b3d;
          margin-left: 0.08rem;
        }

        .right-container {
          width: 0.08rem;
          height: 0.14rem;
          background-image: url("../../assets/pc/stake/right.png");
          margin-right: 0.16rem;
          margin-left: auto;
        }
      }
      .stake-chain-slide {
        position: absolute;
        width: 3.4rem;
        margin-top: 0.04rem;

        ul {
          background-color: white;
          border-radius: 0.04rem;
          li {
            height: 0.4rem;
            width: 3.08rem;
            margin: 0 auto;
            border-bottom: #e8eaec 0.01rem solid;
            display: flex;
            align-items: center;

            &:hover span {
              color: #2761e7;
            }
            img {
              height: 0.28rem;
              width: 0.28rem;
            }
            span {
              font-size: 0.14rem;
              margin-left: 0.08rem;
              font-family: PingFangSC, PingFangSC-Regular;
              color: #3a3b3d;

              &.chosen-chain {
                color: #2761e7;
              }
            }
          }
        }
      }
    }

    .transfer {
      font-size: 0.14rem;
      margin: 0.32rem auto;
      width: 4.4rem;
      text-align: center;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #666666;
    }
    .address,
    .button-container {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .msg-modal,
  .withdraw-modal,
  .deposit-modal {
    border-radius: 0.16rem;
    font-size: 0.16rem;
    padding-bottom: 0.3rem;

    .float-right {
      float: right;
    }

    input {
      width: 100%;
      padding: 0.3rem;
      margin: 0.2rem 0 0.8rem;
      border-radius: 0.2rem;
      border: 1px solid #d8d8d8;
    }

    p {
      font-size: 0.13rem;
    }

    h3.title {
      color: #242424;
      margin: auto;
      text-align: center;
      padding: 0.6rem 0.16rem 0.3rem;
      font-size: 0.3rem;
    }

    .wrap {
      padding: 0.12rem 0.25rem;
    }
  }
  .stake-content-container {
    margin-top: 0.3rem;
    height: 9.4rem;
    background-image: url("../../assets/mobile/stake/bg.png");
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    .btn {
      padding: 0.4rem;
      font-size: 0.26rem;
      &.disable {
        color: #999;
      }

      &.primary {
        background-color: transparent;
        color: #fff;
        border-radius: 0;
        background-image: url("../../assets/pc/stake/btn-long.png");

        &:active {
          background-image: url("../../assets/pc/stake/btn-long-active.png");
          transform: translate(0.02rem, 0.02rem);
        }

        &.all-btn {
          position: absolute;
          top: 0.4rem;
          right: 0.2rem;
          width: auto;
          padding: 0.16rem 0.4rem;
          background-color: transparent;
          font-size: 0.12rem;
          color: #fff;
          background-image: url("../../assets/pc/stake/btn.png");

          &:active {
            background-image: url("../../assets/pc/stake/btn-active.png");
            transform: translate(0.02rem, 0.02rem);
          }
        }
      }
    }
    .btn-container.col-2 {
      .btn {
        width: 50%;
        background-color: transparent;
        background-image: url("../../assets/pc/stake/btn-normal.png");
        color: #2761e7;

        &.primary {
          background-color: transparent;
          color: #fff;
          background-image: url("../../assets/pc/stake/btn.png");

          &:active {
            background-image: url("../../assets/pc/stake/btn-active.png");
            transform: translate(0.02rem, 0.02rem);
          }
        }
      }
    }

    &::after {
      content: "";
      height: 11.51rem;
      width: 100%;
      position: absolute;
      background-image: url("../../assets/pc/stake/bgShadow.png");
      background-size: 100%;
      background-repeat: no-repeat;
      z-index: -79;
      top: 0.5rem;
    }

    .amount-container {
      height: auto;
    }

    .staked-amount {
      font-size: 0.5rem;
    }

    .title {
      font-size: 0.4rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: bold;
      color: #333333;
      line-height: 0.37rem;
      margin-top: 2rem;
      margin-bottom: 0.8rem;
      padding: 0.09rem 0.5rem 0.05rem;
      width: 100%;
      height: auto;
      text-align: center;
      //margin-top: 1.87rem;
    }
    .stake-chain-container {
      margin-top: 0.37rem;
      width: 4.9rem;
      cursor: pointer;

      .label-p {
        font-size: 0.26rem;
        color: #333333;
        line-height: 0.36rem;
        label {
          font-family: PingFangSC, PingFangSC-Regular;
          color: #333333;
        }
      }
      .stake-chain {
        box-sizing: content-box;
        margin-left: -0.02rem;
        width: 4.9rem;
        height: 0.63rem;
        background: rgba(255, 255, 255, 0.2);
        border: 0.02rem solid #ffffff;
        border-radius: 0.04rem;
        margin-top: 0.11rem;
        display: flex;
        align-items: center;

        .chain-container {
          width: 0.4rem;
          height: 0.4rem;
          margin-left: -0.11rem;

          &:first-child {
            margin-left: 0.23rem;
          }
        }
        .chain-title {
          font-size: 0.24rem;
          font-family: PingFangSC, PingFangSC-Regular;
          color: #3a3b3d;
          margin-left: 0.1rem;
        }

        .right-container {
          width: 0.1rem;
          height: 0.2rem;
          background-image: url("../../assets/pc/stake/right.png");
          margin-right: 0.24rem;
          margin-left: auto;
        }
      }
      .stake-chain-slide {
        position: absolute;
        width: 4.9rem;
        margin-top: 0.1rem;

        ul {
          background-color: white;
          border-radius: 0.04rem;
          li {
            height: 0.6rem;
            width: 4.44rem;
            margin: 0 auto;
            border-bottom: #e8eaec 0.01rem solid;
            display: flex;
            align-items: center;

            img {
              height: 0.4rem;
              width: 0.4rem;
            }
            span {
              font-size: 0.24rem;
              margin-left: 0.1rem;
              font-family: PingFangSC, PingFangSC-Regular;
              color: #3a3b3d;

              &.chosen-chain {
                color: #2761e7;
              }
            }
          }
        }
      }
    }

    .transfer {
      display: none;
    }
    .address {
      margin-top: 0.4rem;
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #3a3b3d;
      line-height: 0.33rem;
      transform: scale(0.8);
    }
    .button-container {
      margin-top: 0.24rem;
      width: 4.32rem;
      height: 1rem;
      font-size: 0.22rem;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #ffffff;

      &.normal {
        background-image: url("../../assets/pc/button.png");
      }
      &.down {
        background-image: url("../../assets/pc/button_down.png");
        transform: translate(0.03rem, 0.03rem);
      }
    }
  }
}
</style>
