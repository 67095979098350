<template>
  <div>
    <Header />
    <div class="bg pic-full"></div>
    <!-- <warn class="warn" :home-lang="home"></warn> -->
    <div class="home">
      <main class="fee-main">
        <fee-content class="fee-content"></fee-content>
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Layouts/Header.vue';
import Footer from '../components/Layouts/Footer.vue';
// import Warn from "@/components/contents/warn";
import FeeContent from '@/components/fee/feeContent';

export default {
  name: 'Fee',
  components: {
    // Warn,
    FeeContent,
    Header,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less">
@media screen and (min-width: 767px) {
  .fee-main {
    width: 8.33rem;
    margin: 0 auto;
    margin-top: 0.59rem;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  .fee-main {
    margin-bottom: 0.3rem;
  }
}
</style>
